import React from "react";
import { useState, useEffect } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Instafeed from "instafeed.js";
import Navbar from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import Slider from "react-slick";
import ReCAPTCHA from "react-google-recaptcha";
import CountUp, { startAnimation } from "react-countup";
import Accordion from "react-bootstrap/Accordion";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import HeaderImg from "../Assets/images/courses-detials/webinar-concept-illustration_114360-4884.png";
import Certificate from "../Assets/images/courses-detials/certificate.023.jpg";
import CertificateImg from "../Assets/images/courses-detials/dff06bd51f8b2a01b21d9eb25508ba52.svg";
import CertificateLogo from "../Assets/images/courses-detials/Nac-300x300.png";
import Group153 from "../Assets/images/courses-detials/Group153.svg";
import Group154 from "../Assets/images/courses-detials/Group154.svg";
import CourseImgOne from "../Assets/images/courses-detials/10-12-how-is-data-science-used-in-finance.png";
import CourseImg2 from "../Assets/images/courses-detials/Image1.png";
import CertificateLogo0 from "../Assets/images/logo/google.png";
import PlacementImg from "../Assets/images/courses-detials/Ellipse12.png";
import PlacementImg1 from "../Assets/images/courses-detials/Ellipse13.png";
import PlacementImg2 from "../Assets/images/courses-detials/Ellipse14.png";
import PlacementImg3 from "../Assets/images/courses-detials/Ellipse15.png";
import PlacementImg4 from "../Assets/images/courses-detials/Ellipse16-1.png";
import PlacementImg5 from "../Assets/images/courses-detials/Ellipse17-2.png";
import CertificateLogo1 from "../Assets/images/courses-detials/office-chair-svgrepo-com(2).svg";
import CertificateLogo2 from "../Assets/images/courses-detials/building-svgrepo-com.svg";
import technologyLogo from "../Assets/images/courses-detials/technology.svg";
import ulimage from "../Assets/images/courses.html/Group45.png";
import cardCourseDetailsLogo from "../Assets/images/courses-detials/class-whiteboard-svgrepo-com.svg";
import "./CourseDetails.css";
import {
  getCourse,
  getCourseByUrl,
} from "../../Services/Auth/Actions/CourseActions";
import { getAllCourses } from "../../Services/Auth/Actions/CourseActions";
import {
  getAllPlacements,
  getPlacementsNoPagination,
} from "../../Services/Auth/Actions/PlacementActions";
import { payment, sentMail } from "../../Services/Auth/Actions/PaymentActions";
import $ from "jquery";
import { Button } from "@material-ui/core";
import { registerNowCreate } from "../../Services/Auth/Actions/HomeActions";
import { bookADemoCreate } from "../../Services/Auth/Actions/HomeActions";
import VisibilitySensor from "react-visibility-sensor";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Meta from "../Meta/Meta";
import {
  createEnquiryLuminarCRM,
  getDropDownListLuminarCRM,
  openAdmissionFormCRM,
  REACT_APP_RECAPTCHA_SITE_KEY,
} from "../../Services/CRMservice";

const axios = require("axios");

export const CourseDetails = () => {
  const { url } = useParams();
  const [number, setNumber] = useState(0);
  const [placement, setPlacement] = useState([]);
  const [bookFlag, setBookFlag] = useState(false);
  const [registerFlag, setRegisterFlag] = useState(false);
  const [module, setModule] = useState([]);
  const [course, setCourse] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [extra, setExtra] = useState(false);
  const recaptchaRef = React.useRef();
  const [isVisible, setIsVisible] = useState(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const onChange = (isVisible) => {
    console.log("Element is now %s", isVisible ? "visible" : "hidden");
    setIsVisible(isVisible);
  };

  const navigate = useNavigate();
  const [registerNow, setRegisterNow] = useState({
    name: "",
    email: "",
    phone: "",
    courseName: "",
  });
  const { name, email, phone, courseName } = registerNow;

  const [bookADemo, setBookADemo] = useState({
    fullName: "",
    DemoEmail: "",
    phoneNumber: "",
    DemoCourseName: "",
    place: "",
    recaptchResponseToken: "",
  });
  const { fullName, DemoEmail, phoneNumber, DemoCourseName, place } = bookADemo;
  const [captchError, setCaptchaError] = useState();
  const [razorpayData, setRazorpayData] = useState({
    firstName: "",
    emailPay: "",
    phoneNo: "",
    lastName: "",
  });
  const { firstName, emailPay, phoneNo, lastName } = razorpayData;

  useEffect(() => {
    getDropDownListLuminarCRM("course").then((data) => {
      setCourseList(data);
    });

    getPlacementsNoPagination().then((response) => {
      setPlacement([...placement, ...response]);
    });

    $(".navbar-toggler.navbar-light:not(.collapsed)").click();

    getCourseByUrl(url).then((data) => {
      setCourse([data]);
      if (data.modules.length > 0) {
        setModule([data.modules[0]]);
      }
    });
    //   var feed = new Instafeed({
    //     accessToken:
    //       "IGQVJYempYQ29hRVdkcDJvajJqX0pVYzZAjREdkNE5HWU9nZA0VJeFFCM0ptanZAfNEdYbkUycEtfVlA2RmkzSTBLVm82ZAmRfQ0tmWksxenlLeVNEUnEtZAXVXZAW50OVBtSnhKUmplNW9tWkdNWDNEUHZADcAZDZD",
    //   });
    //   feed.run();
  }, []);
  //   slick start
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
  };
  const placementsettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  // handlechange for register popup

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setRegisterNow({
      ...registerNow,
      [name]: e.target.value,
    });
  };

  //razorpay fn

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
    });
  }
  async function displayRazorpay(values) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load.Are you online?");
    }
    {
      console.log("values", values);
    }

    const data = payment(values).then((datas) => {
      //  const data = await fetch('http://localhost:9000/api/payment/order', {method:'POST',body:values}).then((t)=>t.json())
      const options = {
        key: "rzp_live_tADna9sORaFrNT", //"rzp_test_Y1enzHkmVCmPBb", // Enter the Key ID generated from the Dashboard
        amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Luminar technolab",
        description: "Registration Fee",
        // "image": {ulimage},
        order_id: `${datas.orderId}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          axios({
            method: "post",
            // url: `http://localhost:9000/api/payment/updateTransactionId`,
            // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/payment/updateTransactionId`,
            url: `https://api.luminartechnolab.com/api/payment/updateTransactionId`,

            data: {
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          })
            .then(function (response) {
              //handle success
              console.log(response);
            })
            .catch(function (response) {
              //handle error
              alert(response);
            });
          sentMail(datas.result.email, datas.orderId, values.title);

          navigate("/success");
        },
        prefill: {
          name: "Luminar",
          email: datas.result.email,
          contact: datas.result.phone,
          image: { ulimage },
        },
        customer: {
          contact: "+" + datas.result.phone,
          email: datas.result.email,
        },
        //   "theme": {
        //     "color": "green"
        // }
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        alert("Your payment transaction is failed ");
      });
      paymentObject.open();
      document.getElementById("rzp-button1").onclick = function (e) {
        e.preventDefault();
      };
    });
  }

  //razorpay submit form

  const paymentSubmitHandler = (e) => {
    e.preventDefault();
    let values = {};
    values.firstName = razorpayData.firstName;
    values.lastName = razorpayData.lastName;
    values.email = razorpayData.emailPay;
    values.phone = razorpayData.phoneNo;
    values.fee = course[0].RegistrationFee;
    values.title = course[0].title;
    displayRazorpay(values);
  };

  /////////////////////////////
  const formSubmitHandler = (e) => {
    e.preventDefault();

    registerNowCreate(registerNow)
      .then(() => {
        setRegisterNow({
          name: "",
          email: "",
          phone: "",
          courseName: "",
        });
        toast.success("Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        setRegisterFlag(false);
      })
      .catch((err) => {
        toast.error("Submission Failed", {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  //for book A demo

  const handleChangeBook = (name) => (e) => {
    e.preventDefault();
    setBookADemo({
      ...bookADemo,
      [name]: e.target.value,
    });
  };
  //for razorpay

  const handleChangeRazorPay = (name) => (e) => {
    e.preventDefault();
    setRazorpayData({
      ...razorpayData,
      [name]: e.target.value.trim(),
    });
  };
  const demoSubmitHandler = (e) => {
    e.preventDefault();

    if (!bookADemo.recaptchResponseToken) {
      setCaptchaError('CAPTCHA is required. Please complete the CAPTCHA.');
      return;
    }

    const payload = {
      name: bookADemo.fullName,
      contactNumber: bookADemo.phoneNumber,
      email: bookADemo.DemoEmail,
      city: bookADemo.place,
      course: bookADemo.DemoCourseName,
      recaptchResponseToken: bookADemo.recaptchResponseToken,
    };
    console.log(payload, "payyyyyyyyy");
    createEnquiryLuminarCRM(payload)
      .then((data) => {
        setBookADemo({
          fullName: "",
          DemoEmail: "",
          phoneNumber: "",
          DemoCourseName: "",
          place: "",
          recaptchResponseToken: "",
        });
        toast.success("Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        recaptchaRef.current.reset();
        setBookFlag(false);
      })
      .catch((err) => {
        toast.error("Submission Failed", {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <div>
      {course &&
        course.map((customer, i) => {
          return (
            <>
              <Meta
                title={customer.metaTitle}
                content={customer.metaDescription}
              />

              <section id="courses-detials" className="course-webbanner">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="course-detials-header-text">
                        {ReactHtmlParser(customer.bannerHeading)}

                        {ReactHtmlParser(customer.bannerContent)}
                        <div class="course-detials-header-button-wrapper">
                          <button
                            class="course-detials-header-button one"
                            data-toggle="modal"
                            data-target="#exampleModalCenters"
                            onClick={() => {
                              setBookADemo({
                                fullName: "",
                                DemoEmail: "",
                                phoneNumber: "",
                                DemoCourseName: "",
                                place: "",
                              });
                              setBookFlag(true);
                            }}
                          >
                            Book a Demo
                          </button>
                          {/* <button
                            class="courses-paragraph-button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            onClick={() => {
                              setRegisterNow({
                                name: "",
                                email: "",
                                phone: "",
                                courseName: "",
                              });
                              setRegisterFlag(true)
                            }}
                          >
                            Register Now
                          </button> */}
                          <button
                            class="courses-paragraph-button"
                            // data-toggle="modal"
                            // data-target="#enroll"
                            // onClick={() => {
                            //   setRazorpayData({
                            //     firstName: "",
                            //     emailPay: "",
                            //     phoneNo: "",
                            //     lastName: "",
                            //   });
                            // }}
                            onClick={() => openAdmissionFormCRM()}
                          >
                            Register Now
                          </button>
                        </div>

                        {/* Book now pop-up start */}
                        {bookFlag && (
                          <div
                            className="modal fade"
                            id="exampleModalCenters"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content" id="modaldemo">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Book a Demo
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setBookFlag(false)}
                                  >
                                    <span aria-hidden="true" className="x">
                                      ×
                                    </span>
                                  </button>
                                </div>

                                <form onSubmit={demoSubmitHandler}>
                                  <div className="modal-body form-group m-2 form-group">
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      className="form-control m-3 "
                                      name={fullName}
                                      value={fullName}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      required
                                      minLength={2}
                                      maxLength={25}
                                      onChange={handleChangeBook("fullName")}
                                    ></input>
                                    <br />

                                    <input
                                      type="text"
                                      placeholder="Phone.No"
                                      className="form-control m-3"
                                      name={phoneNumber}
                                      value={phoneNumber}
                                      required
                                      pattern={phoneRegExp}
                                      minlength="10"
                                      maxlength="13"
                                      title="Field accepts only Numbers"
                                      onChange={handleChangeBook("phoneNumber")}
                                    ></input>
                                    <br />
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      className="form-control m-3"
                                      name={DemoEmail}
                                      value={DemoEmail}
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                      size="30"
                                      onChange={handleChangeBook("DemoEmail")}
                                    ></input>
                                    <br />
                                    <input
                                      type="text"
                                      placeholder="City"
                                      className="form-control m-3"
                                      name={place}
                                      value={place}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      minLength={3}
                                      maxLength={10}
                                      onChange={handleChangeBook("place")}
                                    ></input>
                                    <br />
                                    <select
                                      class="browser-default custom-select m-3 "
                                      name={DemoCourseName}
                                      value={DemoCourseName}
                                      required
                                      minLength={2}
                                      maxLength={10}
                                      onChange={handleChangeBook(
                                        "DemoCourseName"
                                      )}
                                    >
                                      <option value="">
                                        Please select Course
                                      </option>
                                      {courseList &&
                                        courseList.map((item, i) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <div className="col mt-3">
                                      <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={(token) => {
                                          if (token) {
                                            setBookADemo({
                                              ...bookADemo,
                                              recaptchResponseToken: token,
                                            });
                                            setCaptchaError("");
                                          }
                                        }}
                                      />
                                      {captchError ? (
                                        <div className="error-messge">
                                          {captchError}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-modal-color btn-lg m-2"
                                      data-dismiss="modal"
                                      onClick={() => setBookFlag(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-modal-color btn-lg m-2"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* book a now pop-up end*/}
                        {registerFlag && (
                          <div
                            className="modal fade"
                            id="exampleModalCenter"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Register Now
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setRegisterFlag(false)}
                                  >
                                    <span aria-hidden="true" className="x">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                <form onSubmit={formSubmitHandler}>
                                  <div className="modal-body form-group m-2 form-group">
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      className="form-control m-3 "
                                      name={name}
                                      value={name}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      required
                                      minLength={3}
                                      maxLength={25}
                                      onChange={handleChange("name")}
                                    ></input>
                                    <br />
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      className="form-control m-3"
                                      name={email}
                                      value={email}
                                      required
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                      size="30"
                                      onChange={handleChange("email")}
                                    ></input>
                                    <br />
                                    <input
                                      type="text"
                                      placeholder="Phone.No"
                                      className="form-control m-3"
                                      name={phone}
                                      value={phone}
                                      required
                                      pattern="\d*"
                                      minlength="10"
                                      maxlength="13"
                                      title="Field accepts only Numbers"
                                      onChange={handleChange("phone")}
                                    ></input>
                                    <br />
                                    <select
                                      class="browser-default custom-select m-3 "
                                      name={courseName}
                                      value={courseName}
                                      required
                                      minLength={2}
                                      maxLength={10}
                                      onChange={handleChange("courseName")}
                                    >
                                      <option value="">
                                        Please select Course
                                      </option>
                                      {courseList &&
                                        courseList.map((item, i) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary btn-lg m-2"
                                      data-dismiss="modal"
                                      onClick={() => setRegisterFlag(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-modal-color btn-lg m-2"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* register now button pop-up end */}
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="course-detials-header-image">
                        <img
                          src={ReactHtmlParser(customer.sliderImg)}
                          alt="heading-image"
                          class="img-fluid
                                    course-detials-header-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="courses-detials" className="course-mobilebanner">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="course-detials-header-text">
                        {ReactHtmlParser(customer.bannerHeading)}
                        <div class="col-12 col-md-6">
                          <div class="course-detials-header-image">
                            <img
                              src={ReactHtmlParser(customer.sliderImg)}
                              alt="heading-image"
                              class="img-fluid
                                    course-detials-header-image"
                            />
                          </div>
                        </div>

                        {ReactHtmlParser(customer.bannerContent)}
                        <div class="course-detials-header-button-wrapper">
                          <button
                            class="course-detials-header-button one"
                            data-toggle="modal"
                            data-target="#exampleModalCenters1"
                            onClick={() => {
                              console.log("clicked");

                              setBookADemo({
                                fullName: "",
                                DemoEmail: "",
                                phoneNumber: "",
                                DemoCourseName: "",
                                place: "",
                              });
                              setBookFlag(true);
                            }}
                          >
                            Book a Demo
                          </button>
                          <button
                            class="courses-paragraph-button"
                            // data-toggle="modal"
                            // data-target="#enroll"
                            // onClick={() => {
                            //   setRazorpayData({
                            //     firstName: "",
                            //     emailPay: "",
                            //     phoneNo: "",
                            //     lastName: "",
                            //   });
                            // }}
                            onClick={() => openAdmissionFormCRM()}
                          >
                            Register Now
                          </button>
                        </div>

                        {bookFlag && (
                          <div
                            className="modal fade"
                            id="exampleModalCenters1"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content" id="modaldemo">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Book a Demo
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setBookFlag(false)}
                                  >
                                    <span aria-hidden="true" className="x">
                                      ×
                                    </span>
                                  </button>
                                </div>

                                <form onSubmit={demoSubmitHandler}>
                                  <div className="modal-body form-group m-2 form-group">
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      className="form-control m-3 "
                                      name={fullName}
                                      value={fullName}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      required
                                      minLength={2}
                                      maxLength={25}
                                      onChange={handleChangeBook("fullName")}
                                    ></input>
                                    <br />

                                    <input
                                      type="text"
                                      placeholder="Phone.No"
                                      className="form-control m-3"
                                      name={phoneNumber}
                                      value={phoneNumber}
                                      required
                                      pattern={phoneRegExp}
                                      minlength="10"
                                      maxlength="13"
                                      title="Field accepts only Numbers"
                                      onChange={handleChangeBook("phoneNumber")}
                                    ></input>
                                    <br />
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      className="form-control m-3"
                                      name={DemoEmail}
                                      value={DemoEmail}
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                      size="30"
                                      onChange={handleChangeBook("DemoEmail")}
                                    ></input>
                                    <br />
                                    <input
                                      type="text"
                                      placeholder="City"
                                      className="form-control m-3"
                                      name={place}
                                      value={place}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      minLength={3}
                                      maxLength={10}
                                      onChange={handleChangeBook("place")}
                                    ></input>
                                    <br />
                                    <select
                                      class="browser-default custom-select m-3 "
                                      name={DemoCourseName}
                                      value={DemoCourseName}
                                      required
                                      minLength={2}
                                      maxLength={10}
                                      onChange={handleChangeBook(
                                        "DemoCourseName"
                                      )}
                                    >
                                      <option value="">
                                        Please select Course
                                      </option>
                                      {courseList &&
                                        courseList.map((item, i) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <div className="col mt-3">
                                      <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={(token) => {
                                          if (token) {
                                            setBookADemo({
                                              ...bookADemo,
                                              recaptchResponseToken: token,
                                            });
                                            setCaptchaError("");
                                          }
                                        }}
                                      />
                                      {captchError ? (
                                        <div className="error-messge">
                                          {captchError}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-modal-color btn-lg m-2"
                                      data-dismiss="modal"
                                      onClick={() => setBookFlag(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-modal-color btn-lg m-2"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* book a now pop-up end*/}
                        {registerFlag && (
                          <div
                            className="modal fade"
                            id="exampleModalCenter"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Register Now
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setRegisterFlag(false)}
                                  >
                                    <span aria-hidden="true" className="x">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                <form onSubmit={formSubmitHandler}>
                                  <div className="modal-body form-group m-2 form-group">
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      className="form-control m-3 "
                                      name={name}
                                      value={name}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      required
                                      minLength={3}
                                      maxLength={25}
                                      onChange={handleChange("name")}
                                    ></input>
                                    <br />
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      className="form-control m-3"
                                      name={email}
                                      value={email}
                                      required
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                      size="30"
                                      onChange={handleChange("email")}
                                    ></input>
                                    <br />
                                    <input
                                      type="text"
                                      placeholder="Phone.No"
                                      className="form-control m-3"
                                      name={phone}
                                      value={phone}
                                      required
                                      pattern="\d*"
                                      minlength="10"
                                      maxlength="13"
                                      title="Field accepts only Numbers"
                                      onChange={handleChange("phone")}
                                    ></input>
                                    <br />
                                    <select
                                      class="browser-default custom-select m-3 "
                                      name={courseName}
                                      value={courseName}
                                      required
                                      minLength={2}
                                      maxLength={10}
                                      onChange={handleChange("courseName")}
                                    >
                                      <option value="">
                                        Please select Course
                                      </option>
                                      {courseList &&
                                        courseList.map((item, i) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary btn-lg m-2"
                                      data-dismiss="modal"
                                      onClick={() => setRegisterFlag(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-modal-color btn-lg m-2"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* register now button pop-up end */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="course-detials-course-about">
                <div class="container course-detials-course-about-container">
                  <div class="row course-detials-course-about-row">
                    <div class="col-12">
                      <div className="cousredetails-section">
                        {ReactHtmlParser(customer.detailPageHeading)}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="course-detials-course-certificate-background">
                  <div class="container course-detials-container">
                    <div class="row">
                      <div class="col-12 col-md-5">
                        <img
                          src={Certificate}
                          alt="certificate"
                          class="course-detials-course-certificate-image
                                    img-fluid"
                        />
                      </div>
                      <div
                        class="col-12 col-md-7 d-flex flex-column
                                align-items-start course-detials-content "
                      >
                        <div class="course-detials-course-certificate-detials">
                          <img
                            src={CertificateImg}
                            alt="certificate image"
                            class="course-badge"
                          />
                        </div>
                        {ReactHtmlParser(customer.certificationSection)}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {module && module.length > 0 && (
                <section id="course-detilas-module-table">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="course-detilas-module-table
                                text-center"
                        >
                          <h1 class="course-detilas-module-table-heading">
                            What You Will Learn{" "}
                          </h1>
                          <p class="course-detilas-module-table-subheading">
                            Luminar Technolab - Software Training Institute
                          </p>
                          <div id="main">
                            <div class="container">
                              {customer.modules?.map((data, j) => {
                                return (
                                  <Accordion Key={j}>
                                    <Accordion.Item eventKey={data}>
                                      <Accordion.Header>
                                        {data.moduleName}{" "}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <h1 class="course-module-name-sm">
                                          {customer.title}
                                          {/* Big Data (Hadoop & Spark) */}
                                        </h1>
                                        <br></br>
                                        <span class="course-module-one-sm">
                                          {data.moduleTitle}{" "}
                                        </span>
                                        <br></br>
                                        <br></br>
                                        <p class="course-module-one-para">
                                          {data.description}
                                        </p>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                );
                              })}
                            </div>
                          </div>

                          <div class="course-detials-contents-wrapper">
                            <div class="course-detials-modules">
                              <Button>
                                {" "}
                                <div class="course-detials-module zero">
                                  MODULE
                                </div>
                              </Button>
                              {customer.modules?.map((data, j) => {
                                return (
                                  <div>
                                    <Button
                                      onClick={(e) => {
                                        setModule([data]);
                                        setNumber(j);
                                      }}
                                      value={data}
                                    >
                                      <div
                                        className={
                                          number === j
                                            ? "course-detials-module one"
                                            : "course-detials-module"
                                        }
                                      >
                                        {data.moduleName}
                                      </div>
                                    </Button>
                                  </div>
                                );
                              })}
                            </div>

                            <div class="course-lessons">
                              <div class="course-module-heading">
                                <h1 class="course-module-name">
                                  {" "}
                                  {customer.title}
                                </h1>
                              </div>
                              <div class="course-module-lessons-wrapper">
                                {module.length > 0 &&
                                  module?.map((data, i) => {
                                    return (
                                      <div class="course-module-lessons-content">
                                        <div class="course-module-lessons">
                                          <span
                                            class="course-module-lesson-name
                                                        one"
                                          >
                                            {data.moduleTitle}
                                          </span>
                                          <p class="course-module-lesson-content">
                                            {data.description}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              <section id="course-detials-certified-courses">
                <div class="container text-center">
                  <div class="row">
                    <div
                      class="col-12 text-center
                                    course-detials-certificate-logo"
                    >
                      <img
                        src={CertificateLogo}
                        alt="certificate-logo"
                        class="img-fluid
                                        course-detials-certificate-image"
                      />
                      <p class="course-detials-certifiecate-caption">
                        Certified
                      </p>
                    </div>
                  </div>

                  <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center">
                      <div class="course-detials-certifiecate-course-heading-wrapper">
                        <p class="course-detials-certifiecate-course-heading-two">
                          {customer.title}
                        </p>
                      </div>

                      <p class="course-detials-certifiecate-course-paragraph">
                        {customer.description}
                      </p>

                      <div
                        class="card
                                        course-detials-certifiecate-card"
                      >
                        <div class="course-detials-certifiecate-circle">
                          <img
                            src={customer.icon}
                            alt="course-detials-certifiecate-logo"
                            class="img-fluid
                                                course-detials-certifiecate-card-logo"
                          />
                        </div>
                        <div class="card-body">
                          <h5
                            class="card-title
                                                course-detials-batch-detials"
                          >
                            New Batch Opening
                          </h5>
                          <div class="course-detials-card-content-section">
                            <div class="course-detials-card-content-wrapper">
                              <div class="course-detials-card-criteria">
                                <p class="course-detials-card-criteria-next-batch">
                                  Next Batch:
                                </p>
                              </div>
                              <div className="nextBatch">
                                <div class="course-detials-card-info">
                                  <p className="sub-date">Cochin</p> &nbsp;
                                  &nbsp; &nbsp;
                                  <p class="course-detials-card-data">
                                    {customer.nextBatch}
                                  </p>
                                </div>
                                <div class="course-detials-card-info">
                                  <p className="sub-date">Calicut</p> &nbsp;
                                  &nbsp; &nbsp;
                                  <p class="course-detials-card-data">
                                    {customer.nextBatchCalicut}
                                  </p>
                                </div>
                                <div class="course-detials-card-info">
                                  <p className="sub-date">Trivandrum</p> &nbsp;
                                  &nbsp; &nbsp;
                                  <p class="course-detials-card-data">
                                    {customer.nextBatchTvm}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="course-detials-card-content-wrapper">
                              <div class="course-detials-card-criteria">
                                <span class="course-detials-card-criteria-duration">
                                  Duration:
                                </span>
                              </div>
                              <div class="course-detials-card-info">
                                <p class="course-detials-card-data">
                                  {customer.duration}
                                </p>
                              </div>
                            </div>

                            <div class="course-detials-card-content-wrapper">
                              <div class="course-detials-card-criteria">
                                <p class="course-detials-card-criteria-next-batch">
                                  Course Fees:
                                </p>
                              </div>
                              <div className="nextBatch">
                                <div class="course-detials-card-info">
                                  <p className="sub-date">
                                    Fees (One time Payment mode)
                                  </p>{" "}
                                  &nbsp; &nbsp; &nbsp;
                                  <p class="course-detials-card-data">
                                    {customer.onlineFee}
                                  </p>
                                </div>
                                <div class="course-detials-card-info">
                                  <p className="sub-date">Fees (EMI Mode)</p>{" "}
                                  &nbsp; &nbsp; &nbsp;
                                  <p class="course-detials-card-data">
                                    {customer.fee}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="course-detials-card-content-wrapper">
                              <div class="course-detials-card-criteria">
                                <span class="course-detials-card-criteria-course-fees">
                                  Registration Fee:
                                </span>
                              </div>
                              <div
                                class="course-detials-card-info
                                                        card-fees"
                              >
                                <p class="course-detials-card-fees">
                                  {customer.RegistrationFee}{" "}
                                </p>
                              </div>
                            </div>

                            <div class="course-detials-card-content-wrapper">
                              <div class="course-detials-card-criteria">
                                <span class="course-detials-card-criteria-certification">
                                  Certification:
                                </span>
                              </div>
                              <div class="course-detials-card-info">
                                <p
                                  class="course-detials-card-certification
                                                            course-detials-card-data"
                                >
                                  {customer.certification}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="course-detials-for-new-admission">
                            For New Admissions Click Enroll Button
                          </div>

                          {/* <button class="btn
                                                card-course-enroll text-center" data-toggle="modal" data-target="#enroll" onClick={()=>{navigate(`/payment/${id}`)}}>ENROLL</button> */}

                          <button
                            class="btn card-course-enroll text-center"
                            // data-toggle="modal"
                            // data-target="#enroll"
                            // onClick={() => {
                            //   setRazorpayData({
                            //     firstName: "",
                            //     emailPay: "",
                            //     phoneNo: "",
                            //     lastName: "",
                            //   });
                            // }}
                            onClick={() => openAdmissionFormCRM()}
                          >
                            ENROLL
                          </button>
                          <div
                            className="modal fade"
                            id="enroll"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content" id="modalenroll">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Enroll Now
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true" className="x">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                <form onSubmit={paymentSubmitHandler}>
                                  <div className="modal-body form-group m-2 form-group">
                                    <input
                                      type="text"
                                      placeholder="First Name"
                                      name={firstName}
                                      value={firstName}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      required
                                      minLength={2}
                                      maxLength={25}
                                      onChange={handleChangeRazorPay(
                                        "firstName"
                                      )}
                                      className="form-control m-3"
                                    ></input>
                                    <br />
                                    <input
                                      type="text"
                                      placeholder="Last Name"
                                      required
                                      name={lastName}
                                      value={lastName}
                                      pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                      minLength={1}
                                      maxLength={20}
                                      onChange={handleChangeRazorPay(
                                        "lastName"
                                      )}
                                      className="form-control m-3"
                                    ></input>
                                    <br />
                                    <input
                                      type="tel"
                                      name={phoneNo}
                                      value={phoneNo}
                                      placeholder="Phone Number(Please include 91)"
                                      pattern="\d*"
                                      minlength="10"
                                      maxlength="13"
                                      required
                                      onChange={handleChangeRazorPay("phoneNo")}
                                      className="form-control m-3"
                                    ></input>
                                    <br />
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      name={emailPay}
                                      value={emailPay}
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                      size="30"
                                      required
                                      className="form-control m-3"
                                      onChange={handleChangeRazorPay(
                                        "emailPay"
                                      )}
                                    ></input>
                                    <br />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="submit"
                                      id="rzp-button1"
                                      className="btn btn-primary btn-lg m-2"
                                    >
                                      Pay
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* enroll  pop-up end */}
                        </div>
                      </div>
                    </div>
                    <div class="course-detials-certifiecate-course-text-bottom-wrapper">
                      {/* <p class="course-detials-certifiecate-course-text-bottom">
                        * Classes by Real time working Professionals & We Cover
                        Big Data Analytics & Data Science with AI in Single
                        Course with Tableau - Data Visualisation
                      </p> */}
                    </div>
                    <div class="">
                      <img
                        src={Group153}
                        alt="course-detials-background-hexagon-image"
                        class="course-detials-certified-courses-background-image-left"
                      />
                    </div>
                    <div class="">
                      <img
                        src={Group154}
                        alt="course-detials-background-hexagon-image"
                        class="course-detials-certified-courses-background-image-right"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section
                id="course-detials-course-info"
                className="large-device-course-details-content"
              >
                <div className="course-detials-section-one">
                  <div class="container">
                    <div class="row">
                      <div class="col-12 col-md-6 d-flex">
                        <div className="course-detials-image-one-section">
                          <img
                            src={customer.detailsPageImg1}
                            alt="course-image-image-one"
                            class="course-detials-image-one"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <div className="course-detials-content-one">
                          {ReactHtmlParser(customer.whyLearnCourse)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="course-detials-section-two">
                  <div class="container">
                    <div class="row ">
                      <div class="col-12 col-md-6">
                        <div className="course-detials-content-two">
                          {ReactHtmlParser(customer.howWeHelp)}
                        </div>
                      </div>

                      <div class="col-12 col-md-6  d-flex">
                        <div class="course-detials-image-two-section">
                          <img
                            src={customer.detailsPageImg2}
                            alt="course-detials-course-info-paragraph-subject-image"
                            class="course-detials-image-two"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div className="extra-content">
                    {ReactHtmlParser(customer.extraContent)}
                  </div>
                </div>
              </section>

              {/* small device */}
              <section
                id="course-detials-course-info"
                className="small-device-course-details-content"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-md-6 d-flex">
                      <div className="course-detials-image-one-section">
                        <img
                          src={customer.detailsPageImg1}
                          alt="course-image-image-one"
                          class="course-detials-image-one"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div className="course-detials-content-one">
                        {ReactHtmlParser(customer.whyLearnCourse)}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container">
                  <div class="row ">
                    <div class="col-12 col-md-6">
                      <div className="course-detials-content-two">
                        {ReactHtmlParser(customer.howWeHelp)}
                      </div>
                    </div>

                    <div class="col-12 col-md-6  d-flex">
                      <div class="course-detials-image-two-section">
                        <img
                          src={customer.detailsPageImg2}
                          alt="course-detials-course-info-paragraph-subject-image"
                          class="course-detials-image-two"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div className="extra-content">
                    {ReactHtmlParser(customer.extraContent)}
                  </div>
                </div>
              </section>

              <section id="course-detials-admission-section">
                <div class="container">
                  <div class="row text-center">
                    <div class="col-12">
                      <h1 class="course-detials-admission-heading">
                        Admission Process
                      </h1>
                      <p class="course-detials-admission-subheading">
                        There are 3 simple steps in the Admission Process which
                        is detailed below:
                      </p>
                    </div>
                  </div>
                  <div
                    class="row text-left
                            course-detials-admission-card-section"
                  >
                    <div class="col-4">
                      <div class="card course-detials-admission">
                        <div class="card-body">
                          <h5
                            class="card-title
                                            course-detials-admission"
                          >
                            01
                          </h5>
                          <h6
                            class="card-subtitle
                                            course-detials-admission-title-one"
                          >
                            Fill the Application Form
                          </h6>
                          <p
                            class="card-text text-left
                                            course-detials-admission-detials-one"
                          >
                            Apply by filling a simple online application form to
                            kick-start the admission process.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="card course-detials-admission">
                        <div class="card-body">
                          <h5
                            class="card-title
                                            course-detials-admission"
                          >
                            02
                          </h5>
                          <h6
                            class="card-subtitle
                                            course-detials-admission-title-two"
                          >
                            Interview Process & Demo Session
                          </h6>
                          <p
                            class="card-text text-left
                                            course-detials-admission-detials-two"
                          >
                            Go through a screening call with Admissions office
                            and Book your demo.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="card course-detials-admission">
                        <div class="card-body">
                          <h5
                            class="card-title
                                            course-detials-admission"
                          >
                            03
                          </h5>
                          <h6
                            class="card-subtitle
                                            course-detials-admission-title-three"
                          >
                            Join the Program
                          </h6>
                          <p
                            class="card-text text-left
                                            course-detials-admission-detials-three"
                          >
                            Block your seat with a payment of ₹ 1000 to begin
                            learning with prep course.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="carousel-slider-coursedetailspage">
                <Slider {...settings}>
                  <div className="carousel-slider-card-01">
                    <div class="card-01">
                      <div class="card-body">
                        <h5
                          class="card-title
                                            course-detials-admission"
                        >
                          01
                        </h5>
                        <h6
                          class="card-subtitle
                                            course-detials-admission-title-one"
                        >
                          Fill the Application Form
                        </h6>
                        <p
                          class="card-text text-left
                                            course-detials-admission-detials-para"
                        >
                          Apply by filling a simple online application form to
                          kick-start the admission process.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-slider-card-01">
                    <div class="card-01">
                      <div class="card-body">
                        <h5
                          class="card-title
                                            course-detials-admission"
                        >
                          02
                        </h5>
                        <h6
                          class="card-subtitle
                                            course-detials-admission-title-two"
                        >
                          Interview Process & Demo Session
                        </h6>
                        <p
                          class="card-text text-left
                                            course-detials-admission-detials-two"
                        >
                          Go through a screening call with Admissions office and
                          Book your demo.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-slider-card-01">
                    <div class="card-01">
                      <div class="card-body">
                        <h5
                          class="card-title
                                            course-detials-admission"
                        >
                          03
                        </h5>
                        <h6
                          class="card-subtitle
                                            course-detials-admission-title-three"
                        >
                          Join the Program
                        </h6>
                        <p
                          class="card-text text-left
                                            course-detials-admission-detials-three"
                        >
                          Block your seat with a payment of ₹ 15,000 to begin
                          learning with prep course.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>

              <section id="course-detials-prefer-us">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="course-detials-prefer-uss">
                        Why should you prefer us
                      </h2>
                    </div>
                  </div>
                  <div class="row course-detials-prefer-us">
                    <div
                      class="col-6 col-md-3
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                        <VisibilitySensor onChange={onChange}>
                          <img
                            src={CertificateLogo0}
                            alt="certificate-logo"
                            class="course-detials-prefer-us-image-one"
                          />
                        </VisibilitySensor>
                        <div>
                          {isVisible ? (
                            <div class="course-detials-prefer-count-one ">
                              <CountUp end={1000} duration={3} />+
                            </div>
                          ) : null}
                        </div>

                        <p class="course-detials-prefer-name">Google reviews</p>
                      </div>
                    </div>

                    <div
                      class="col-6 col-md-3 
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                        <VisibilitySensor onChange={onChange}>
                          <img
                            src={technologyLogo}
                            alt="technology-logo"
                            class="course-detials-prefer-us-images
                                        two"
                          />
                        </VisibilitySensor>
                        <div>
                          {isVisible ? (
                            <div class="course-detials-prefer-count-one ">
                              <CountUp end={30} duration={3} />+
                            </div>
                          ) : null}
                        </div>
                        <p class="course-detials-prefer-name">Technologies</p>
                      </div>
                    </div>

                    <div
                      class="col-6 col-md-3 
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                        <VisibilitySensor onChange={onChange}>
                          <img
                            src={CertificateLogo1}
                            alt="certificate-logo"
                            class="course-detials-prefer-us-images-three"
                          />
                        </VisibilitySensor>
                        <div>
                          {isVisible ? (
                            <div class="course-detials-prefer-count-one ">
                              <CountUp end={100} duration={3} />%
                            </div>
                          ) : null}
                        </div>
                        <p class="course-detials-prefer-name">Placement</p>
                      </div>
                    </div>

                    <div
                      class="col-6 col-md-3 
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                        <VisibilitySensor onChange={onChange}>
                          <img
                            src={CertificateLogo2}
                            alt="certificate-logo"
                            class="course-detials-prefer-us-images
                                        four"
                          />
                        </VisibilitySensor>
                        <div>
                          {isVisible ? (
                            <div class="course-detials-prefer-count-one ">
                              <CountUp end={50} duration={3} />+
                            </div>
                          ) : null}
                        </div>
                        <p class="course-detials-prefer-name">Companies</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="course-detials-placement">
                <div class="container">
                  <div class="row">
                    <div class="col-12 text-center">
                      <h3 class="course-detials-placement-heading">
                        Placements
                      </h3>
                      <p class="course-detials-placement-subheading">
                        Our mission is to provide 100% placements to students
                      </p>
                    </div>
                  </div>

                  <div class="row text-center course-detials-placement-profile course-detials-placement-container-large-device">
                    <Slider {...placementsettings}>
                      {placement &&
                        placement.map((data, i) => {
                          return (
                            <div class="col-12 col-md-2 placement-image-section">
                              <img
                                src={data.image}
                                alt="placement-image"
                                class="placement-image"
                              />
                              <p class="course-detials-placements-profile-name">
                                {data.name}
                                <span class="course-detials-placements-profile-qualification">
                                  {data.professionalCourse}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                </div>

                {/* <!-- for small device --> */}
                <div
                  class="container
                        course-detials-placement-container-small-device"
                >
                  <div class="row">
                    <div class="col-12">
                      <div
                        id="carouselExampleIndicators"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <ol class="carousel-indicators placement-indicator">
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="0"
                            class="active"
                          ></li>
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="1"
                          ></li>
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to="2"
                          ></li>
                        </ol>

                        <div class="carousel-inner">
                          {placement &&
                            placement.map((data, i) => {
                              return (
                                <div
                                  class={
                                    i == 0
                                      ? "carousel-item active"
                                      : "carousel-item"
                                  }
                                  data-interval="1000"
                                >
                                  <div class="row">
                                    <div
                                      class="col-12
                                                    course-detials-placement-carousel-column"
                                    >
                                      <img
                                        src={data.image}
                                        alt="placement-image"
                                        class="img-fluid
                                                        course-detials-placement-carousel-image"
                                      />
                                      <p class="course-detials-placements-profile-name">
                                        {data.name}
                                        <span class="course-detials-placements-profile-qualification">
                                          {data.professionalCourse}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Footer />
            </>
          );
        })}
    </div>
  );
};
