import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import Meta from "../Meta/Meta";
import { CourseDetails } from "./CourseDetails";
import HeaderImg from "../Assets/images/courses.html/online-certification-concept-with-books_23-2148573636.png";
import MaskGroup from "../Assets/images/courses.html/MaskGroup4.png";
import Search from "../Assets/images/courses.html/Search.png";
import ML from "../Assets/images/courses.html/machine-learning.png";
import Python from "../Assets/images/courses.html/python-language-logotype-svgrepo-com.png";
import Mern from "../Assets/images/courses.html/icon.png";
import Android from "../Assets/images/courses.html/android-logo-svgrepo-com.png";
import DataScience from "../Assets/images/courses.html/data-science.png";
import BA from "../Assets/images/courses.html/analytics.png";
import Testing from "../Assets/images/courses.html/testing(1).png";
import { getAllCourses } from "../../Services/Auth/Actions/CourseActions";
import "./CourseList.css";
import { registerNowCreate } from "../../Services/Auth/Actions/HomeActions";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { css } from "@emotion/react";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import FadeLoader from "react-spinners/FadeLoader";
const override = css`
  border-color: #6d1d8e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const CourseList = () => {
  const [flag, setFlag] = useState(false);

  const [courseList, setCourseList] = useState([]);
  const [registerNow, setRegisterNow] = useState({
    name: "",
    email: "",
    phone: "",
    courseName: "",
  });
  const { name, email, phone, courseName } = registerNow;
  const [search, setSearch] = useState("");
  const [course, setCourse] = useState([{ courses: [] }]);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#6d1d8e");
  const { courses } = course;
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCourses().then((data) => {
      setCourse({
        ...course,
        courses: data.sort((a, b) => a.order - b.order),
      });
      setLoading(false);
    });
    $(".navbar-toggler.navbar-light:not(.collapsed)").click();
  }, []);

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setRegisterNow({
      ...registerNow,
      [name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();

    registerNowCreate(registerNow)
      .then((data) => {
        setRegisterNow({
          name: "",
          email: "",
          phone: "",
          courseName: "",
        });
        toast.success("Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        setFlag(false);
      })
      .catch((err) => {
        toast.error("Submission Failed", {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <Meta
        title="Best Data Science, Big Data Courses Kochi,Kerala |Luminar Technolab"
        content="Luminar Technolab, No.1 Software Training Institute offers various Software Courses along with 100% Guaranteed Placement assistance. We provide Big Data Training, Data Science, Python,Mean Stack,Software Testing and many more"
      />

      {loading ? (
        <div className="loadersection">
          <div class="loader"></div>
        </div>
      ) : (
        <div class="courseList-body">
          <section id="courses">
            <div class="container">
              <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-5 courses-header-wrapper">
                  <h1 class="courses-heading">
                    Best Data Science,Big Data Courses
                  </h1>
                  <h3 class="courses-sub-heading">
                    Luminar Technolab | Kochi,Kerala
                  </h3>
                  <p class="courses-heading-paragraph">
                    Luminar Technolab, No.1 Software Training Institute offers
                    various Software Courses along with 100% Guaranteed
                    Placement assistance. We provide Big Data Training, Data
                    Science, Python,Mean Stack,Software Testing & many more
                  </p>
                  <div class="courses-paragraph-button-section">
                    <Link to="/contact">
                      <button class="courses-paragraph-button">
                        Enquire now
                      </button>
                    </Link>
                    {/* <button class="courses-paragraph-button" data-toggle="modal" data-target="#exampleModalCenterregister"   onClick={()=>{
            setRegisterNow({
                name: '',
                email: '',
                phone: '',
                courseName: ''
            })
            setFlag(true);

                    }}>Register Now</button> */}
                  </div>
                  {/* register now pop-up start */}

                  {flag && (
                    <div
                      className="modal fade"
                      id="exampleModalCenterregister"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Register Now
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setFlag(false)}
                            >
                              <span aria-hidden="true" className="x">
                                ×
                              </span>
                            </button>
                          </div>
                          <form onSubmit={formSubmitHandler}>
                            <div className="modal-body form-group m-2 form-group">
                              <input
                                type="text"
                                placeholder="Name"
                                className="form-control m-3 "
                                name={name}
                                value={name}
                                pattern="^\S.+\S$"
                                minLength={2}
                                maxLength={25}
                                required
                                onChange={handleChange("name")}
                              ></input>
                              <br />
                              <input
                                type="email"
                                placeholder="Email"
                                className="form-control m-3"
                                name={email}
                                value={email}
                                required
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                onChange={handleChange("email")}
                              ></input>
                              <br />
                              <input
                                type="text"
                                placeholder="Phone.No"
                                className="form-control m-3"
                                name={phone}
                                value={phone}
                                pattern="\d*"
                                minlength="10"
                                maxlength="13"
                                title="Field accepts only Numbers"
                                required
                                onChange={handleChange("phone")}
                              ></input>
                              <br />
                              <select
                                class="browser-default custom-select m-3 "
                                name={courseName}
                                value={courseName}
                                required
                                minLength={2}
                                maxLength={25}
                                onChange={handleChange("courseName")}
                              >
                                <option value="">Please select Course</option>

                                {courses &&
                                  courses.map((item, i) => {
                                    return (
                                      <option value={item.title}>
                                        {item.title}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-modal-color btn-lg m-2"
                                data-dismiss="modal"
                                onClick={() => setFlag(false)}
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                className="btn btn-modal-color btn-lg m-2"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* register now button pop-up end */}
                </div>
                <div class="col-sm-12  col-md-7 courses-header-image-wrapper">
                  <img
                    src={HeaderImg}
                    alt="header-image-certification"
                    class="header-image-certification"
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="training-programs">
            <div class="container">
              <div class="row d-flex">
                <div class="col-sm-12">
                  <div class="training-program-wrapper">
                    <h1 class="training-program-heading">
                      Our 100% Job Oriented Training Programs
                    </h1>
                    <p class="training-program-paragraph">
                      We are focused on providing advanced level software
                      training like Big Data Training, Hadoop Training, Data
                      Science Training, Python Training, Machine Learning
                      Training, Selenium Training in Cochin Kerala.
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 courses-input-column">
                  <span>
                    <input
                      type="text"
                      class="search-course"
                      placeholder="Search Your Course"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <img src={Search} class="img-fluid search-icon" />
                  </span>
                </div>
              </div>

              <div class="row training-program-row one">
                {courses &&
                  courses
                    .filter((val) => {
                      if (search == "") {
                        return val;
                      } else if (search) {
                        if (val.title) {
                          if (
                            val.title
                              .toLowerCase()
                              .includes(search.toLocaleLowerCase())
                          ) {
                            return val;
                          }
                        }
                      }
                    })
                    .map((customer, i) => {
                      return (
                        <div
                          class="col-sm-12 col-md-4 training-program-column"
                          id="training-program-column"
                        >
                          <div class="card training-program-card">
                            {/* <a href="../website/courses-detials.html"> */}
                            <div class="card-body courseList">
                              <div class="training-program-card-circle rounded-circle">
                                <img
                                  className="card-imag "
                                  src={customer.icon}
                                  alt="machine-learning"
                                />
                              </div>
                              <h5 class="training-program-card-one-heading">
                                {customer.title}
                              </h5>
                              <p
                                class="card-text
                            training-program-card-one-text"
                              >
                                {customer.description}
                              </p>
                              <p class="training-program-card-one-detials">
                                <span class="training-program-card-title">
                                  Next Batch:
                                </span>
                                <span className="sub-date-courseList">
                                  Cochin :{" "}
                                </span>
                                {customer.nextBatch}
                                <br />
                                <span className="sub-date-courseList">
                                  Calicut :{" "}
                                </span>
                                {customer.nextBatchCalicut}
                                <br />
                                <span className="sub-date-courseList">
                                  Trivandrum :{" "}
                                </span>
                                {customer.nextBatchTvm}
                                <span class="training-program-card-title">
                                  Duration:
                                </span>
                                {customer.duration}
                                <span class="training-program-card-title">
                                  Course Fee: <br />
                                </span>{" "}
                                <span className="sub-date-courseList">
                                  Fees (EMI Mode) :{" "}
                                </span>
                                {customer.fee}
                                <br />
                                <span className="sub-date-courseList">
                                  Fees (One time Payment mode) :{" "}
                                </span>
                                {customer.onlineFee}
                                {/* <span class="training-program-card-title">
                                    Offline
                                </span>{" "}
                                <p>{customer.fee}</p> */}
                              </p>
                              <Link to={`/course-detail/${customer.url}`}>
                                <button class="training-program-card-button ">
                                  View More
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>

              <div class="row text-center">
                <div class="col-12">
                  <button class="training-program-view-all-cards-button">
                    View All
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section id="courses-why-luminar">
            <div class="container-fluid">
              <div class="row courses-why-luminar">
                <div class="col-12 col-md-6 courses-why-luminar-image-section">
                  <img
                    src={MaskGroup}
                    class="why-luminar-image-left img-fluid"
                    alt="why-luminar-image"
                  />
                </div>

                <div
                  class="col-12 col-md-6
                why-luminar-text-section"
                >
                  <h1 class="why-luminar-heading">Why Luminar Technolab</h1>
                  <p class="why-luminar-paragraph">
                    At Luminar Technolab we offer a comprehensive course that
                    covers the whole aspects of the Data Science Ecosystem
                    including Big Data Engineering, Data Science, Data
                    Analytics, Data Visualization - Tableau, data processing,
                    Data Munging, Machine Learning, Deep Learning & Artificial
                    Intelligence.
                  </p>
                  <ul class="why-luminar-list">
                    <li> ISO 9001:2015 Certified Institution</li>
                    <li>
                      {" "}
                      Affiliated to NACTET
                      (http://www.nactetindia.org/affiliation.php)
                    </li>
                    <li>
                      {" "}
                      Training by highly experienced and certified professionals
                    </li>
                    <li>
                      {" "}
                      No slideshow (PPT) training, fully Hands-on training
                    </li>
                    <li> Interactive session with interview QA’s</li>
                    <li> Real-time projects scenarios & Certification Help</li>
                    <li> Most competitive & affordable course fees</li>
                    <li> Placement support for all courses</li>
                    <li> List of established & satisfied clients & students</li>
                    <li> Located Near Infopark Cochin.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      )}
    </>
  );
};
